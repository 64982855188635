import React from "react";
import { Outlet } from "react-router-dom";

const GameLayout = () => {
  return (
    <div
      className="flex h-screen bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(/background.jpg)` }}
    >
      <main className="flex-1">
        <Outlet /> {/* This renders the child routes */}
      </main>
    </div>
  );
};

export default GameLayout;
