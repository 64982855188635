import React from "react";

const OwnerDashboard = () => {
  return (
    <div>
      <h1>Owner Dashboard</h1>
      <p>Welcome, Owner! You have access to the Owner-specific dashboard.</p>
    </div>
  );
};

export default OwnerDashboard;
