import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../services/AuthContext"; // Use AuthContext to check if the user is logged in
import Loading from "./Loading";

const ProtectedRoute = ({ component: Component, role, ...rest }) => {
  const { user, loading } = useAuth();

  // Wait for loading to finish
  if (loading) {
    return <Loading />; // You can replace this with a better loading component
  }

  // If no user or the role does not match, redirect to login
  if (!user || (role && user.role !== role)) {
    return <Navigate to="/login" />;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
