import React from "react";
import { Spin } from "antd";

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center bg-gradient-to-r from-blue-800 to-gray-800">
      <div className="mb-4 animate-bounce">
        <Spin size="large" />
      </div>
      <div className="mt-4 text-2xl font-extrabold text-white">Loading...</div>
      <div className="mt-2 text-lg text-gray-200">
        Please hold on as we gather your data!
      </div>
    </div>
  );
};

export default Loading;
