import React, { useState } from "react";
import { Link } from "react-router-dom";
import { register } from "../services/authService";
import Notification from "./Notification";
import { MdEmail, MdLock } from "react-icons/md"; // Importing icons
import Logo from "../images/logo.png";

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();
    const { email, firstName, lastName, password, confirmPassword } =
      event.target.elements;

    setErrors({});

    let errorMessages = {};
    if (password.value !== confirmPassword.value) {
      errorMessages.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(errorMessages).length > 0) {
      setErrors(errorMessages);
      return;
    }

    setLoading(true);
    try {
      await register({
        email: email.value,
        firstName: firstName.value,
        lastName: lastName.value,
        password: password.value,
      });

      setNotification({
        type: "success",
        message: "Registration Successful",
        description: "Your account has been created successfully!",
      });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        const backendErrors = err.response.data.errors.reduce((acc, error) => {
          acc[error.path] = error.msg;
          return acc;
        }, {});
        setErrors(backendErrors);
      } else {
        setNotification({
          type: "error",
          message: "Registration Failed",
          description: err.response.data.message,
        });
      }
    }
    setLoading(false);
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-center bg-cover"
      style={{ backgroundImage: `url(/background.jpg)` }} // Update the path to your background image
    >
      <div className="w-full max-w-md p-8 border border-blue-500 rounded-lg shadow-lg bg-gradient-to-r">
        <h2 className="mb-4 text-4xl font-extrabold text-center text-blue-300">
          <div className="flex justify-between mb-4">
            <img src={Logo} width={80} className="animate-bounce" />
            <img src={Logo} width={80} className="animate-bounce" />
          </div>
          REGISTER
        </h2>
        <form onSubmit={onSubmit} noValidate>
          <div className="mb-4">
            <label className="block text-lg text-gray-300">Nickname *</label>
            <div className="flex items-center py-2 border-b border-blue-500">
              <MdEmail className="mr-2 text-blue-300" />
              <input
                type="email"
                name="email"
                className={`w-full px-2 bg-transparent text-white focus:outline-none ${
                  errors.email ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Enter your nickname"
              />
            </div>
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          <div className="flex space-x-4">
            <div className="w-1/2 mb-4 border-b border-blue-500">
              <label className="block text-lg text-gray-300">
                First Name *
              </label>
              <input
                type="text"
                name="firstName"
                className={`w-full h-10 px-2 bg-transparent text-white focus:outline-none ${
                  errors.firstName ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Enter your first name"
              />
              {errors.firstName && (
                <p className="text-sm text-red-500">{errors.firstName}</p>
              )}
            </div>
            <div className="w-1/2 mb-4 border-b border-blue-500">
              <label className="block text-lg text-gray-300">Last Name *</label>
              <input
                type="text"
                name="lastName"
                className={`w-full h-10 px-2 bg-transparent text-white focus:outline-none ${
                  errors.lastName ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Enter your last name"
              />
              {errors.lastName && (
                <p className="text-sm text-red-500">{errors.lastName}</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-lg text-gray-300">Password *</label>
            <div className="flex items-center py-2 border-b border-blue-500">
              <MdLock className="mr-2 text-blue-300" />
              <input
                type="password"
                name="password"
                className={`w-full h-10 px-2 bg-transparent text-white focus:outline-none ${
                  errors.password ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Enter your password"
              />
            </div>
            {errors.password && (
              <p className="text-sm text-red-500">{errors.password}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-lg text-gray-300">
              Confirm Password *
            </label>
            <div className="flex items-center py-2 border-b border-blue-500">
              <MdLock className="mr-2 text-blue-300" />
              <input
                type="password"
                name="confirmPassword"
                className={`w-full h-10 px-2 bg-transparent text-white focus:outline-none ${
                  errors.confirmPassword ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Confirm your password"
              />
            </div>
            {errors.confirmPassword && (
              <p className="text-sm text-red-500">{errors.confirmPassword}</p>
            )}
          </div>

          <button
            type="submit"
            className={`w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-500 transition duration-200 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Registering..." : "REGISTER"}
          </button>

          <div className="mt-4 text-center">
            <p className="text-gray-400">
              Already have an account?{" "}
              <Link to="/login" className="text-blue-300 hover:underline">
                Login
              </Link>
            </p>
          </div>
        </form>

        {notification && (
          <Notification
            type={notification.type}
            message={notification.message}
            description={notification.description}
            onClose={() => setNotification(null)}
          />
        )}
      </div>
    </div>
  );
};

export default RegisterForm;
