import React, { useEffect } from "react";

const Notification = ({
  type,
  message,
  description,
  onClose,
  duration = 3000,
}) => {
  let backgroundColor;

  // Set background color based on notification type
  switch (type) {
    case "success":
      backgroundColor = "bg-green-500";
      break;
    case "error":
      backgroundColor = "bg-red-500";
      break;
    case "warning":
      backgroundColor = "bg-yellow-500";
      break;
    default:
      backgroundColor = "bg-blue-500";
  }

  // Auto-close the notification after a certain duration (default: 3000ms)
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [onClose, duration]);

  return (
    <div className="fixed top-4 right-4 z-50">
      <div
        className={`${backgroundColor} text-white px-4 py-3 rounded-md shadow-lg max-w-sm w-full`}
      >
        <div className="flex justify-between items-center">
          <div>
            <p className="font-semibold">{message}</p>
            <p className="text-sm">{description}</p>
          </div>
          <button onClick={onClose} className="ml-4 text-lg font-bold">
            ✕
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification;
