import React, { useEffect, useState } from "react";
import { FiEdit, FiTrash2 } from "react-icons/fi"; // Import react-icons
import { MdOutlineFileUpload } from "react-icons/md"; // File upload icon
import axios from "axios";
import { useAuth } from "../../services/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Input,
  Button,
  Modal,
  Form,
  notification,
  Select,
  DatePicker,
  Upload,
} from "antd";

const { Option } = Select;

const MyTests = () => {
  const { user, token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || user.role !== "owner") {
      navigate("/login"); // Redirect if not an owner
    }
  }, [user, navigate]);

  const [tests, setTests] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [newTest, setNewTest] = useState({
    title: "",
    description: "",
    priceGBP: "",
    priceEUR: "",
    priceUSD: "",
    priceNGN: "",
    discount: "",
    durationStart: null,
    durationEnd: null,
    testFile: null, // File upload state
    subject: "",
    discountPercentage: "",
  });
  const [editingTestId, setEditingTestId] = useState(null); // To track if we're editing
  const [loading, setLoading] = useState(false); // Loading state

  const [currentPage, setCurrentPage] = useState(1);
  const [testsPerPage] = useState(5);

  useEffect(() => {
    const fetchTests = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`/owner/tests?search=${searchTerm}`, {
          headers: { Authorization: token, "Content-Type": "application/json" },
          withCredentials: true, // Include credentials if needed
        });
        setTests(res.data);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTests();
  }, [searchTerm, token]);

  const handleModalOpen = () => {
    setNewTest({
      title: "",
      description: "",
      priceGBP: "",
      priceEUR: "",
      priceUSD: "",
      priceNGN: "",
      discount: "",
      durationStart: null,
      durationEnd: null,
      testFile: null,
      subject: "",
      discountPercentage: "",
    });
    setEditingTestId(null); // Set editing state to null
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleInputChange = (key, value) => {
    setNewTest({ ...newTest, [key]: value });
  };

  const handleSubmit = async (values) => {
    // Form validation for required fields
    if (!values.title || !values.priceUSD || !newTest.testFile) {
      notification.error({
        message: "Error",
        description: "Title, Price in USD, and File are required.",
      });
      return;
    }

    const formData = new FormData();
    for (const key in newTest) {
      formData.append(key, newTest[key]);
    }

    try {
      setLoading(true);
      if (editingTestId) {
        await axios.put(`/owner/tests/${editingTestId}`, formData, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        });
        notification.success({ message: "Test updated successfully" });
      } else {
        await axios.post("/owner/tests", formData, {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        });
        notification.success({ message: "Test created successfully" });
      }
      handleModalClose();
    } catch (err) {
      console.error(err);
      notification.error({ message: "Error saving test" });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (test) => {
    setEditingTestId(test._id);
    setNewTest({
      title: test.title,
      description: test.description,
      priceGBP: test.priceGBP,
      priceEUR: test.priceEUR,
      priceUSD: test.priceUSD,
      priceNGN: test.priceNGN,
      discount: test.discount,
      durationStart: test.durationStart,
      durationEnd: test.durationEnd,
      subject: test.subject,
      discountPercentage: test.discountPercentage,
      testFile: null, // Clear file for editing
    });
    setModalVisible(true);
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this test?",
      onOk: async () => {
        try {
          await axios.delete(`/owner/tests/${id}`, {
            headers: { Authorization: token },
          });
          setTests(tests.filter((test) => test._id !== id));
          notification.success({ message: "Test deleted successfully" });
        } catch (err) {
          console.error(err);
          notification.error({ message: "Error deleting test" });
        }
      },
    });
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price (GBP)",
      dataIndex: "priceGBP",
      key: "priceGBP",
    },
    {
      title: "Price (EUR)",
      dataIndex: "priceEUR",
      key: "priceEUR",
    },
    {
      title: "Price (USD)",
      dataIndex: "priceUSD",
      key: "priceUSD",
    },
    {
      title: "Price (NGN)",
      dataIndex: "priceNGN",
      key: "priceNGN",
    },
    {
      title: "Discount",
      dataIndex: "discountPercentage",
      key: "discountPercentage",
    },
    {
      title: "Duration",
      render: (text, test) => (
        <span>
          {new Date(test.durationStart)
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")}{" "}
          to{" "}
          {new Date(test.durationEnd)
            .toISOString()
            .slice(0, 19)
            .replace("T", " ")}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, test) => (
        <span>
          <Button
            type="link"
            icon={<FiEdit />}
            onClick={() => handleEdit(test)}
          />
          <Button
            type="link"
            icon={<FiTrash2 />}
            danger
            onClick={() => handleDelete(test._id)}
          />
        </span>
      ),
    },
  ];

  return (
    <div className="p-4">
      <div className="flex items-center justify-between mb-4">
        <Input
          placeholder="Search tests..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 300 }}
        />
        <Button type="primary" onClick={handleModalOpen}>
          New Test
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={tests}
        pagination={{
          current: currentPage,
          pageSize: testsPerPage,
          total: tests.length,
          onChange: (page) => setCurrentPage(page),
        }}
        loading={loading}
        rowKey="_id"
      />

      {/* Modal */}
      <Modal
        title={editingTestId ? "Edit Test" : "Create New Test"}
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800} // Increased width for the modal
      >
        <Form layout="vertical" onFinish={handleSubmit} initialValues={newTest}>
          <Form.Item
            label="Test Title"
            name="title"
            rules={[
              { required: true, message: "Please input the test title!" },
            ]}
          >
            <Input
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <Input.TextArea
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </Form.Item>

          <Form.Item label="Prices">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item
                name="priceGBP"
                noStyle
                rules={[
                  { required: true, message: "Please input the price in GBP!" },
                ]}
              >
                <Input
                  placeholder="GBP"
                  style={{ width: "24%", marginRight: "8px" }}
                  type="number"
                  onChange={(e) =>
                    handleInputChange("priceGBP", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name="priceEUR"
                noStyle
                rules={[
                  { required: true, message: "Please input the price in EUR!" },
                ]}
              >
                <Input
                  placeholder="EUR"
                  style={{ width: "24%", marginRight: "8px" }}
                  type="number"
                  onChange={(e) =>
                    handleInputChange("priceEUR", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item
                name="priceUSD"
                noStyle
                rules={[
                  { required: true, message: "Please input the price in USD!" },
                ]}
              >
                <Input
                  placeholder="USD"
                  style={{ width: "24%", marginRight: "8px" }}
                  type="number"
                  onChange={(e) =>
                    handleInputChange("priceUSD", e.target.value)
                  }
                />
              </Form.Item>

              <Form.Item name="priceNGN" noStyle>
                <Input
                  placeholder="NGN"
                  style={{ width: "24%" }}
                  type="number"
                  onChange={(e) =>
                    handleInputChange("priceNGN", e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="Select Subject/Category" name="subject">
            <Select onChange={(value) => handleInputChange("subject", value)}>
              <Option value="">Select Subject/Category</Option>
              <Option value="Math">Math</Option>
              <Option value="Science">Science</Option>
              <Option value="English">English</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Discount Percentage" name="discountPercentage">
            <Input
              type="number"
              onChange={(e) =>
                handleInputChange("discountPercentage", e.target.value)
              }
            />
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label="Duration Start">
              <DatePicker
                style={{ width: "100%", marginRight: "8px" }}
                onChange={(date, dateString) =>
                  handleInputChange("durationStart", dateString)
                }
              />
            </Form.Item>

            <Form.Item label="Duration End">
              <DatePicker
                style={{ width: "100%" }}
                onChange={(date, dateString) =>
                  handleInputChange("durationEnd", dateString)
                }
              />
            </Form.Item>
          </div>

          <Form.Item label="Upload File (.csv)">
            <Upload
              beforeUpload={(file) => {
                if (
                  file.type !== "text/csv" &&
                  file.type !== "application/vnd.ms-excel"
                ) {
                  notification.error({
                    message: "You can only upload CSV files!",
                  });
                  return false;
                }
                handleInputChange("testFile", file);
                return false; // Prevent automatic upload
              }}
              showUploadList={false}
            >
              <Button icon={<MdOutlineFileUpload />}>
                {newTest.testFile ? newTest.testFile.name : "Click to upload"}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              {editingTestId ? "Update Test" : "Create Test"}
            </Button>
            <Button onClick={handleModalClose} style={{ marginLeft: "8px" }}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MyTests;
