import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../services/authService";
import { useAuth } from "../services/AuthContext";
import Notification from "./Notification";
import { jwtDecode } from "jwt-decode";
import { MdEmail, MdLock } from "react-icons/md"; // Importing icons
import Logo from "../images/logo.png";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [notification, setNotification] = useState(null);
  const navigate = useNavigate();
  const { login: authLogin } = useAuth();

  const onSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = event.target.elements;

    setErrors({});
    setLoading(true);

    try {
      const response = await login({
        email: email.value,
        password: password.value,
      });

      const token = response.data.token;
      const decodedToken = jwtDecode(token);
      const { role, state } = decodedToken;

      if (state !== "active") {
        setNotification({
          type: "error",
          message: "Login Failed",
          description:
            "Your account is pending approval. Please contact support.",
        });
        setLoading(false);
        return;
      }

      authLogin(token);
      setNotification({
        type: "success",
        message: "Login Successful",
        description: "Welcome to your dashboard!",
      });

      if (role === "member") {
        navigate("/member/dashboard");
      } else if (role === "user") {
        navigate("/game");
      } else if (role === "admin") {
        navigate("/admin/dashboard");
      }
    } catch (err) {
      setErrors({ form: "Login failed. Please check your credentials." });
      setNotification({
        type: "error",
        message: "Login Failed",
        description: "Please check your credentials and try again.",
      });
    }

    setLoading(false);
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(/background.jpg)` }} // Update the path to your background image
    >
      <div className="w-full max-w-md p-8 border border-blue-500 rounded-lg shadow-lg bg-gradient-to-r">
        <h2 className="mb-4 text-4xl font-extrabold text-center text-blue-300">
          <div className="flex justify-between mb-4">
            <img src={Logo} width={80} className="animate-bounce" />
            <img src={Logo} width={80} className="animate-bounce" />
          </div>
          LOG IN
        </h2>
        <form onSubmit={onSubmit} noValidate>
          <div className="mb-6">
            <label className="block text-lg text-gray-200">Nickname *</label>
            <div className="flex items-center py-2 border-b border-blue-500">
              <MdEmail className="mr-2 text-blue-300" />
              <input
                type="email"
                name="email"
                className={`w-full px-2 bg-transparent text-white focus:outline-none ${
                  errors.email ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Enter your nickname"
              />
            </div>
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          <div className="mb-6">
            <label className="block text-lg text-gray-200">Password *</label>
            <div className="flex items-center py-2 border-b border-blue-500">
              <MdLock className="mr-2 text-blue-300" />
              <input
                type="password"
                name="password"
                className={`w-full px-2 bg-transparent text-white focus:outline-none ${
                  errors.password ? "border-red-500" : "border-gray-700"
                }`}
                required
                placeholder="Enter your password"
              />
            </div>
            {errors.password && (
              <p className="text-sm text-red-500">{errors.password}</p>
            )}
          </div>

          <button
            type="submit"
            className={`w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-500 transition duration-200 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login to Your Account"}
          </button>

          <div className="mt-4 text-center">
            <p className="text-gray-400">
              Don't have an account?{" "}
              <Link to="/register" className="text-blue-300 hover:underline">
                Register
              </Link>
            </p>
          </div>
        </form>

        {notification && (
          <Notification
            type={notification.type}
            message={notification.message}
            description={notification.description}
            onClose={() => setNotification(null)}
          />
        )}
      </div>
    </div>
  );
};

export default LoginForm;
