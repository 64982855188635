import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import OwnerDashboard from "./pages/member/OwnerDashboard";
import MyTests from "./pages/member/MyTests";
// import SalesReports from "./pages/owner/SalesReports";
// import OwnerProfile from "./pages/owner/Profile";
import GameIndex from "./pages/user/Index";
import Tournament from "./pages/user/Tournament";
// import TesterProfile from "./pages/tester/Profile";
// import YourCourses from "./pages/tester/YourCourses";
import AdminDashboard from "./pages/admin/AdminDashboard";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import { AuthProvider } from "./services/AuthContext"; // Wrap the app with AuthProvider
import OwnerLayout from "./layouts/OwnerLayout";
import GameLayout from "./layouts/GameLayout";
import AdminLayout from "./layouts/AdminLayout";
import NotFound from "./components/NotFound"; // Import the NotFound component

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public routes: If authenticated, user will be redirected */}
          <Route
            path="/login"
            element={<PublicRoute restricted={true} component={LoginForm} />}
          />
          <Route
            path="/register"
            element={<PublicRoute restricted={true} component={RegisterForm} />}
          />

          {/* Protected routes: Only accessible if authenticated */}
          <Route
            path="/owner"
            element={<ProtectedRoute role="member" component={OwnerLayout} />}
          >
            {/* Owner's child routes */}
            <Route path="dashboard" element={<OwnerDashboard />} />
            <Route path="my-tests" element={<MyTests />} />
            {/* <Route path="sales-reports" element={<SalesReports />} /> */}
            {/* <Route path="profile" element={<OwnerProfile />} /> */}
          </Route>

          <Route
            path="/game"
            element={<ProtectedRoute role="user" component={GameLayout} />}
          >
            {/* Game child routes */}
            <Route path="" element={<GameIndex />} />
            {/* This renders GameIndex at /game */}
            <Route path=":id" element={<Tournament />} />
          </Route>

          <Route
            path="/admin"
            element={<ProtectedRoute role="admin" component={AdminLayout} />}
          >
            {/* Admin's child route */}
            <Route path="dashboard" element={<AdminDashboard />} />
          </Route>

          {/* Default route */}
          <Route path="/" element={<Navigate to="/login" />} />
          {/* Default route for undefined paths */}
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
