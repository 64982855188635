import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { useAuth } from "../services/AuthContext";

const Sidebar = ({ links }) => {
  const { logout } = useAuth();
  const location = useLocation(); // Get current location

  return (
    <aside className="z-[1] bg-white h-full max-[600px]:hidden shadow-xl w-[240px] p-6 overflow-y-auto border-r">
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="w-full mt-5">
            <img src="/logo.png" className="m-auto" />
          </div>
          <div className="mt-5 text-center">
            <img className="w-24 h-24 m-auto bg-gray-500 border rounded-full shadow-lg" />
            <div className="font-bold">John Doe</div>
            <div className="px-2 m-auto text-sm font-bold text-white bg-gray-500 w-fit rounded-3xl">
              Owner
            </div>
            <div className="mt-8">
              {links.map((link) => (
                <Link
                  key={link.name}
                  to={link.to}
                  className={`block py-2 m-auto text-left font-semibold px-3 rounded-md ${
                    location.pathname === link.to
                      ? "bg-orange-100 text-orange-600"
                      : "text-gray-600 hover:text-orange-600"
                  }`}
                >
                  {link.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="mx-auto w-full">
          <div
            className="cursor-pointer flex items-center py-2 justify-center text-gray-600 hover:text-orange-600"
            onClick={logout}
          >
            <IoLogOutOutline /> <span className="ml-2">Logout</span>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
