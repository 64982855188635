import React from "react";
import { useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation(); // Get the current route

  // Define a title for each route
  const getTitle = () => {
    switch (location.pathname) {
      case "/owner/dashboard":
        return "Dashboard";
      case "/owner/my-tests":
        return "My Tests";
      case "/owner/sales-reports":
        return "Sales and Reports";
      case "/owner/profile":
        return "Profile";
      default:
        return "Course Dashboard";
    }
  };

  return (
    <header className="bg-white shadow p-4 flex justify-between items-center border-b">
      <h1 className="text-2xl font-semibold text-orange-600">{getTitle()}</h1>
      <div className="flex space-x-4 items-center">
        <button className="relative"></button>
      </div>
    </header>
  );
};

export default Header;
