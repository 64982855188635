import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode"; // Import jwt-decode to decode token
import axios from "axios";

// Create the AuthContext
const AuthContext = createContext();

// Create a custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider component to wrap the app
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store the user info
  const [token, setToken] = useState(null); // Store the token
  const [loading, setLoading] = useState(true); // Loading state to track token check

  // Check if token exists in localStorage and set user state on app load
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      try {
        const decodedToken = jwtDecode(storedToken);
        if (decodedToken.exp * 1000 > Date.now()) {
          setToken(storedToken);
          setUser(decodedToken); // Set user info from the token
          axios.defaults.headers["Authorization"] = storedToken;
        } else {
          localStorage.removeItem("token"); // Remove expired token
        }
      } catch (error) {
        console.error("Invalid token:", error);
        localStorage.removeItem("token");
      }
    }
    setLoading(false); // Finished checking for token
  }, []);

  // Function to log in the user
  const login = (userToken) => {
    setToken(userToken);
    localStorage.setItem("token", userToken); // Store token in localStorage
    axios.defaults.headers["Authorization"] = userToken;
    const decodedToken = jwtDecode(userToken);
    setUser(decodedToken); // Decode and set user info
  };

  // Function to log out the user
  const logout = () => {
    setToken(null);
    setUser(null);
    delete axios.defaults.headers["Authorization"];
    localStorage.removeItem("token"); // Clear token from localStorage
  };

  return (
    <AuthContext.Provider value={{ user, token, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
