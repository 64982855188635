import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

const AdminLayout = () => {
  const ownerLinks = [
    { name: "Dashboard", to: "/owner/dashboard" },
    { name: "My Tests", to: "/owner/my-tests" },
    { name: "Sales and Reports", to: "/owner/sales-reports" },
    { name: "Profile", to: "/owner/profile" },
  ];

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar links={ownerLinks} />
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 p-6 bg-white">
          <Outlet /> {/* This renders the child routes */}
        </main>
        <Footer />
      </div>
    </div>
  );
};

export default AdminLayout;
