import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../services/AuthContext";
import Loading from "./Loading";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const { user, loading } = useAuth();

  // Wait for the loading to complete
  if (loading) {
    return <Loading />;
  }

  // If user is authenticated and the route is restricted, redirect them to their dashboard
  if (user && restricted) {
    if (user.role === "admin") {
      return <Navigate to="/admin/dashboard" />;
    } else if (user.role === "user") {
      return <Navigate to="/game" />;
    } else if (user.role === "member") {
      return <Navigate to="/member/dashboard" />;
    }
  }

  // Otherwise, render the component for public routes
  return <Component {...rest} />;
};

export default PublicRoute;
